import { css } from '@emotion/react'
import { amountToNumber, amountToString, newAmount } from '@orus.eu/amount'
import {
  RCDA_DEDUCTIBLES,
  formatGuaranteeLimit,
  getAmountLabel,
  isMrphDeductible,
  isRcdaDeductible,
  mrphDeductibleCurrentValues,
  mrphDeductibleSupportedValues,
  mutaFormulaGuarantees,
  rcphLoiValues,
  type ActivableProductInformationId,
  type CoverageInvoicingItemInputState,
  type MutaFormulaType,
  type MutaFormulasWithPrice,
  type ProductInformation,
  type QuotePageBodySubscriptionUiElement,
  type RcphLoi,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  Dialog,
  DropdownWithDescription,
  ProductCard,
  Text,
  spacing,
  useDialogVisibility,
  useLanguage,
  useOpenChat,
  useScreenVariant,
  valuesToLabels,
  valuesToStrings,
} from '@orus.eu/pharaoh'
import {
  SectionProduct as MutaSectionProduct,
  type HealthCoverProduct,
} from '@orus.eu/pharaoh/src/components/features/health-cover'
import avatarConfort from '@orus.eu/pharaoh/src/components/features/health-cover/section-product/avatar-confort.png'
import { GuaranteesTable, LoiSliderBlock } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import type { GuaranteesSection } from '@orus.eu/pharaoh/src/components/features/quote-v3/guarantees-table'
import { ProductDetailsDialog } from '@orus.eu/pharaoh/src/components/features/universal-subscription/product-selector/product-details-modal.js'
import { memo, useCallback, useMemo, useState, type ChangeEvent } from 'react'
import { helpContactDetails } from '../../../../../lib/help-contact-details'
import { sendMessage } from '../../../../../lib/tracking/tracking'

import { trpcReact } from '../../../../../client'
import { useNavigateBack } from '../../../../../lib/hooks/use-navigate-back'
import { GuaranteeModal } from '../../../../organisms/guarantee/guarantee-list'
import { OptionalGuaranteeModalDisplayer } from '../../../../organisms/guarantee/option-list'
import { useProductInformation } from '../../../subscription/shared-use-offer'
import { computeGuaranteesSection } from '../../../subscription/utils'
import type { StateProxy } from '../../subscription-v2-props'
import { EsRcphDeductibleSelector } from './es-rcph/EsRcphDeductibleSelector'
import { EsRcphLoiSelector } from './es-rcph/EsRcphLoiSelector'
import { productDetailsData, productsAvatarConfigurations } from './product-information-util.js'
import { ProductOptionsList } from './product-options-list'
import { RcdaHistoryTakeoverSelector } from './rcda-history-takeover'

type ProductQuoteCardProps = {
  productInformation: ProductInformation
  stateProxy: StateProxy
  uiElement: QuotePageBodySubscriptionUiElement
  subscriptionId: string
  embedGuaranteesTable: boolean
  context: 'backoffice' | 'selfonboarding'
  coverageInvoicingItemInputState: CoverageInvoicingItemInputState
}
export const ProductQuoteCard = memo<ProductQuoteCardProps>(function ProductQuoteCard({
  productInformation,
  stateProxy,
  uiElement,
  subscriptionId,
  embedGuaranteesTable,
  context,
  coverageInvoicingItemInputState,
}) {
  const openChat = useOpenChat()
  const language = useLanguage()
  const screenVariant = useScreenVariant()
  const navigateBack = useNavigateBack()

  const {
    selectedGuaranteeState,
    selectedOptionState,
    onGuaranteeClick,
    onInfoOptionClick,
    options,
    perOptionSubOptions,
  } = useProductInformation(productInformation)
  const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
  const [selectedGuarantee] = selectedGuaranteeState
  const [selectedOption] = selectedOptionState

  const [organizationType] =
    trpcReact.organizations.getOrganizationTypeForSubscriptionId.useSuspenseQuery(subscriptionId)

  const guaranteesSection: GuaranteesSection[] = useMemo(() => {
    return computeGuaranteesSection(productInformation.guarantees, productInformation.offers)
  }, [productInformation.guarantees, productInformation.offers])

  const avatar = productsAvatarConfigurations[productInformation.product]
  const details = Object.keys(productDetailsData).includes(productInformation.product)
    ? productDetailsData[productInformation.product as ActivableProductInformationId]
    : null

  const {
    show: showLearnMore,
    hide: hideLearnMore,
    visible: isVisibleLearnMore,
  } = useDialogVisibility('details-' + productInformation.name)

  const {
    show: showGuaranteesTable,
    hide: hideGuaranteesTable,
    visible: isVisibleGuaranteesTable,
  } = useDialogVisibility('guarantees-' + productInformation.name)

  return (
    <ProductCard
      productInformation={productInformation}
      onLearnMoreClick={showLearnMore}
      onShowGuaranteesClick={showGuaranteesTable}
      hasLearnMoreButton={details !== null}
      hasShowGuaranteesButton={guaranteesSection.length > 0}
      showGuaranteesInNewPage={context === 'selfonboarding'}
      priceAndRecurrence={getAmountLabel(
        {
          productType: { type: 'productInformationId', productInformationId: productInformation.product },
          state: coverageInvoicingItemInputState,
          organizationType,
        },
        language,
      )}
    >
      {productInformation.product === 'rcda' ? (
        <RcdaDeductibleSelector stateProxy={stateProxy} uiElement={uiElement} subscriptionId={subscriptionId} />
      ) : (
        <></>
      )}
      {productInformation.product === 'rcda' ? (
        <RcdaHistoryTakeoverSelector
          stateProxy={stateProxy}
          uiElement={uiElement}
          subscriptionId={subscriptionId}
          productInformation={productInformation}
        />
      ) : (
        <></>
      )}

      {context === 'selfonboarding' ? (
        <ProductOptionsList
          productInformation={productInformation}
          stateProxy={stateProxy}
          subscriptionId={subscriptionId}
          uiElement={uiElement}
        />
      ) : null}

      {productInformation.product === 'rc-pro' ? (
        <RcphLoiSelector stateProxy={stateProxy} uiElement={uiElement} subscriptionId={subscriptionId} />
      ) : (
        <></>
      )}

      {productInformation.product === 'es-rcph-pro' || productInformation.product === 'es-rcph-general' ? (
        <>
          <EsRcphDeductibleSelector stateProxy={stateProxy} uiElement={uiElement} subscriptionId={subscriptionId} />
          <EsRcphLoiSelector stateProxy={stateProxy} uiElement={uiElement} subscriptionId={subscriptionId} />
        </>
      ) : (
        <></>
      )}

      {productInformation.product === 'mrph' ? (
        <MrphDeductibleSelector stateProxy={stateProxy} uiElement={uiElement} subscriptionId={subscriptionId} />
      ) : (
        <></>
      )}

      {productInformation.product === 'muta' && context === 'selfonboarding' ? (
        <MutaFormulaSelector stateProxy={stateProxy} uiElement={uiElement} />
      ) : undefined}

      {details !== null && isVisibleLearnMore ? (
        <ProductDetailsDialog
          productId={productInformation.name}
          hide={hideLearnMore}
          fullWidth={screenVariant === 'mobile'}
          avatar={avatar}
          details={details}
        />
      ) : null}
      {embedGuaranteesTable ? (
        <>
          {isVisibleGuaranteesTable ? (
            <>
              <Dialog size="xlarge" onClose={hideGuaranteesTable} title={'Tableau des garanties'}>
                <GuaranteesTable
                  guaranteesSection={guaranteesSection}
                  options={options}
                  perOptionSubOptions={perOptionSubOptions}
                  variant={screenVariant}
                  includedGuaranteesCount={productInformation.guarantees.length}
                  onContactClick={openChat}
                  onGuaranteeClick={onGuaranteeClick}
                  onInfoOptionClick={onInfoOptionClick}
                  quotePageType="static"
                  isMuta={productInformation.product === 'muta'}
                  helpContactDetails={helpContactDetails[operatingZone]}
                />
              </Dialog>
            </>
          ) : null}
          {selectedGuarantee ? <GuaranteeModal guarantee={selectedGuarantee} onClose={navigateBack} /> : null}{' '}
          {selectedOption ? (
            <OptionalGuaranteeModalDisplayer
              optionalGuarantee={selectedOption}
              onClose={useNavigateBack}
              addButton={undefined}
              quotePageType="static"
            />
          ) : null}
        </>
      ) : null}
    </ProductCard>
  )
})

type RcphLoiSelectorProps = {
  subscriptionId: string
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
}
const RcphLoiSelector = memo<RcphLoiSelectorProps>(function RcphLoiSelector({ subscriptionId, uiElement, stateProxy }) {
  const value = stateProxy.readRequired(uiElement.dimensions.rcphLoiDimension)
  const minimum = stateProxy.readRequired(uiElement.dimensions.rcphMinLoiDimension)
  const maximum = stateProxy.readRequired(uiElement.dimensions.rcphMaxLoiDimension)
  const allowedValues = useMemo(
    () => rcphLoiValues.filter((value) => value >= minimum && value <= maximum),
    [maximum, minimum],
  )
  const handleLoiChange = useCallback(
    (value: RcphLoi) => {
      stateProxy.write(uiElement.dimensions.rcphLoiDimension, value)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        rcph_loi: value,
        es_rcph_loi: null,
        yearly_total_premium: amountToNumber(
          stateProxy.readRequired(uiElement.dimensions.hiddenQuote).yearlyTotalPremium,
        ),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcda_deductible: null,
        option: null,
        rcda_history_takeover_years: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  return <LoiSliderBlock allowedValues={allowedValues} initialGuaranteeLimit={value} onChange={handleLoiChange} />
})

type MrphDeductibleSelectorProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
  subscriptionId: string
}
const MrphDeductibleSelector = memo<MrphDeductibleSelectorProps>(function MrphDeductibleSelector({
  uiElement,
  stateProxy,
  subscriptionId,
}) {
  const value = stateProxy.readRequired(uiElement.dimensions.mrphDeductible)

  const valueString = value == undefined ? null : value.toString()
  const handleChange = useCallback(
    (valueString?: string | null) => {
      if (!valueString) return
      const value = parseInt(valueString)
      if (!isMrphDeductible(value)) return
      stateProxy.write(uiElement.dimensions.mrphDeductible, value)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        mrph_deductible: value,
        yearly_total_premium: amountToNumber(
          stateProxy.readRequired(uiElement.dimensions.hiddenQuote).yearlyTotalPremium,
        ),
        rcda_deductible: null,
        es_rcph_deductible: null,
        option: null,
        rcda_history_takeover_years: null,
        rcph_loi: null,
        es_rcph_loi: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  return (
    <DropdownWithDescription
      title="Franchise"
      description="Reste à votre charge suite à un remboursement."
      value={valueString}
      values={mrphDeductibleCurrentStrings}
      labels={mrphDeductibleCurrentLabels}
      supportedValues={mrphDeductibleSupportedStrings}
      supportedLabels={mrphDeductibleSupportedLabels}
      onChange={handleChange}
      aria-label="Franchise"
      size="large"
    />
  )
})

const mrphDeductibleCurrentStrings = valuesToStrings(mrphDeductibleCurrentValues)
const mrphDeductibleCurrentLabels = valuesToLabels(mrphDeductibleCurrentValues)
const mrphDeductibleSupportedStrings = valuesToStrings(mrphDeductibleSupportedValues)
const mrphDeductibleSupportedLabels = valuesToLabels(mrphDeductibleSupportedValues)

type RcdaDeductibleSelectorProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
  subscriptionId: string
}
const RcdaDeductibleSelector = memo<RcdaDeductibleSelectorProps>(function MrphDeductibleSelector({
  uiElement,
  stateProxy,
  subscriptionId,
}) {
  const value = stateProxy.readRequired(uiElement.dimensions.rcdaDeductible)
  const valueString = value == undefined ? null : value.toString()
  const handleChange = useCallback(
    (valueString?: string | null) => {
      if (!valueString) return
      const value = parseInt(valueString)
      if (!isRcdaDeductible(value)) return
      stateProxy.write(uiElement.dimensions.rcdaDeductible, value)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        rcda_deductible: value,
        yearly_total_premium: amountToNumber(
          stateProxy.readRequired(uiElement.dimensions.hiddenQuote).yearlyTotalPremium,
        ),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcph_loi: null,
        es_rcph_loi: null,
        option: null,
        rcda_history_takeover_years: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )
  return (
    <DropdownWithDescription
      title="Franchise"
      description="Reste à votre charge suite à un remboursement."
      value={valueString}
      values={rcdaDeductibleValuesString}
      labels={rcdaDeductibleLabels}
      onChange={handleChange}
      aria-label="Franchise"
      size="large"
    />
  )
})

const rcdaDeductibleValuesString = RCDA_DEDUCTIBLES.map((value) => value.toString())
const rcdaDeductibleLabels = Object.fromEntries(
  RCDA_DEDUCTIBLES.map((value) => [
    value.toString(),
    amountToString(newAmount(value), { addCurrency: true, displayDecimals: false }),
  ]),
)

type MutaFormulaSelectorProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
}

const MutaFormulaSelector = memo<MutaFormulaSelectorProps>(function MutaFormulaSelector({ uiElement, stateProxy }) {
  const possibleClientFormulas = stateProxy.readRequired(uiElement.dimensions.mutaPossibleClientFormulas)
  const formulasWithPrice = stateProxy.readRequired(uiElement.dimensions.mutaFormulasWithPrice)
  const currentFormula = stateProxy.readRequired(uiElement.dimensions.mutaFormula)
  const clientRecommendedFormula = stateProxy.readRequired(uiElement.dimensions.mutaClientRecommendedFormula)
  const customizedFormula = !possibleClientFormulas.includes(clientRecommendedFormula) ? clientRecommendedFormula : null

  const [mutaHealthCoverProductFormulas] = useState(
    getMutaHealthCoverProductFormulas(possibleClientFormulas, formulasWithPrice, customizedFormula),
  )

  const [selectedValue, setSelectedValue] = useState(() => {
    const coverProductFormula = mutaHealthCoverProductFormulas.find((formula) => formula.mutaFormula === currentFormula)
    return coverProductFormula?.formula ?? ''
  })

  const onChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: string) => {
      const coverProductFormula = mutaHealthCoverProductFormulas.find((formula) => formula.formula === value)
      if (coverProductFormula) {
        stateProxy.write(uiElement.dimensions.mutaFormula, coverProductFormula.mutaFormula)
        setSelectedValue(coverProductFormula.formula)
      }
    },
    [mutaHealthCoverProductFormulas, stateProxy, uiElement.dimensions.mutaFormula],
  )

  return (
    <div>
      <div>
        <Text
          variant={'body1Medium'}
          css={css`
            margin-bottom: ${spacing[50]};
          `}
        >
          Niveau de couverture
        </Text>
        <MutaSectionProduct
          products={mutaHealthCoverProductFormulas}
          selectedValue={selectedValue}
          paymentRecurrence={'monthly'}
          isAvatar={false}
          isSimplifiedVersion={false}
          isGuaranteesTable={false}
          value={selectedValue}
          handleChange={onChange}
        />
      </div>
    </div>
  )
})
type HealthCoverProductWithMutaFormula = HealthCoverProduct & { mutaFormula: MutaFormulaType }

function getMutaHealthCoverProductFormulas(
  selectableMutaFormulas: MutaFormulaType[],
  mutaFormulasPrice: MutaFormulasWithPrice,
  customizedFormula: MutaFormulaType | null,
): HealthCoverProductWithMutaFormula[] {
  const formulaEquilibre = selectableMutaFormulas[0]
  const formulaConfort = selectableMutaFormulas[1]
  const formulaExcellence = selectableMutaFormulas[2]
  const guaranteesEquilibre = checkDefinedAndNotNull(mutaFormulaGuarantees[formulaEquilibre])
  const guaranteesConfort = checkDefinedAndNotNull(mutaFormulaGuarantees[formulaConfort])
  const guaranteesExcellence = checkDefinedAndNotNull(mutaFormulaGuarantees[formulaExcellence])
  const guaranteesSpecific = customizedFormula
    ? checkDefinedAndNotNull(mutaFormulaGuarantees[customizedFormula])
    : undefined

  return [
    ...(customizedFormula
      ? [
          {
            formula: 'Formule sur mesure',
            description: '',
            avatar: avatarConfort,
            dentalRate: formatGuaranteeLimit(checkDefinedAndNotNull(guaranteesSpecific).DEN_HPS),
            opticalRate: `Jusqu'à ${formatGuaranteeLimit(
              checkDefinedAndNotNull(guaranteesSpecific).OPT_COMP_T_COMP_FY,
            )}`,
            specRate: formatGuaranteeLimit(checkDefinedAndNotNull(guaranteesSpecific).HO_HO.PA.FY),
            isRecommended: false,
            mutaFormula: customizedFormula,
            ...mutaFormulasPrice[customizedFormula],
          },
        ]
      : []),
    {
      formula: 'Equilibré',
      description: '',
      avatar: avatarConfort,
      dentalRate: formatGuaranteeLimit(guaranteesEquilibre.DEN_HPS),
      opticalRate: `Jusqu'à ${formatGuaranteeLimit(guaranteesEquilibre.OPT_COMP_T_COMP_FY)}`,
      specRate: formatGuaranteeLimit(guaranteesEquilibre.HO_HO.PA.FY),
      isRecommended: false,
      mutaFormula: formulaEquilibre,
      ...mutaFormulasPrice[formulaEquilibre],
    },
    {
      formula: 'Confort',
      description: '',
      avatar: avatarConfort,
      specRate: formatGuaranteeLimit(guaranteesConfort.HO_HO.PA.FY),
      dentalRate: formatGuaranteeLimit(guaranteesConfort.DEN_HPS),
      opticalRate: `Jusqu'à ${formatGuaranteeLimit(guaranteesConfort.OPT_COMP_T_COMP_FY)}`,
      isRecommended: true,
      mutaFormula: formulaConfort,
      ...mutaFormulasPrice[formulaConfort],
    },
    {
      formula: 'Excellence',
      description: '',
      avatar: avatarConfort,
      specRate: formatGuaranteeLimit(guaranteesExcellence.HO_HO.PA.FY),
      dentalRate: formatGuaranteeLimit(guaranteesExcellence.DEN_HPS),
      opticalRate: `Jusqu'à ${formatGuaranteeLimit(guaranteesExcellence.OPT_COMP_T_COMP_FY)}`,
      isRecommended: false,
      mutaFormula: formulaExcellence,
      ...mutaFormulasPrice[formulaExcellence],
    },
  ]
}
